<template>
  <div class="h-full w-screen bg-black">
    <LoadingState v-if="getBlogItemIsLoading" />
    <ErrorState
      v-else-if="getBlogItemIsError"
      :error="getBlogItemError"
      :retry="fetchBlogItem({ slug: this.$route.params.slug })"
    />
    <template v-else>
      <PageBanner
        small
        :banner="title"
        :overlay="'bg-black bg-opacity-85'"
        :source="getBlogItem ? getBlogItem.image.asset.url : null"
      />

      <PageSection>
        <BlogBlocks :content="blogBody" />
        <div
          class="flex w-full justify-between items-center text-left"
        >
          <Author
            :image="getBlogItem.authorImage.asset.url"
            :author="getBlogItem.author"
          />
          <div>
            <router-link :to="`/blog`">
              <button class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4 w-full">
                Back to blog
              </button>
            </router-link>
          </div>
        </div>
      </PageSection>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ErrorState, LoadingState, PageSection, PageBanner, Author } from '@/pages/shared/index';

import blogStore from '@/infrastructure/store/modules/blog/';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'BlogPost',
  components: { 
    PageBanner,
    LoadingState,
    ErrorState,
    PageSection,
    BlogBlocks,
    Author
  },

  computed: {
    ...mapGetters('blog', [ 'getBlogItem', 'getBlogItemError', 'getBlogItemIsError', 'getBlogItemIsLoading' ]),
    blogBody() {
      return this.getBlogItem?.body || [];
    },
    title() {
      if(this.$route?.params?.slug){
        const slug = this.$route.params.slug.replace('-',' ');
        return slug[0].toUpperCase() + slug.substring(1);
      }
      return this.$route.meta.title;
    }
  },
  async created() {
    if(!this.$store.hasModule('blog')) this.$store.registerModule('blog', blogStore);
    await this.fetchBlogItem({ slug: this.$route.params.slug });
  },
  methods: {
    ...mapActions('blog', [ 'fetchBlogItem' ])
  }
};
</script>
